<template>
  <div>
    <img style="width: 153px;height: 38px;margin: 20px 0 0 37px;" src="@/assets/img/hc-logo.png" alt="" srcset="" />
    <div class="modal1" v-if="showData == 'modal1'">
      <a-modal
        v-model="modal1Visible"
        :footer="null"
        :title="null"
        :closable="false"
        centered
        :keyboard="false"
        :maskClosable="false"
        width="600"
      >
        <div v-if="Selection">
          <div class="flex-between">
            <div><h2 class="modal1-title">创建/加入一个企业</h2></div>
            <div><a-button @click="handleLogout">退出</a-button></div>
          </div>
          <p class="modal1-content">您必须创建或者加入一个企业才能查看数据、进行操作。</p>
          <div class="modal1-box">
            <div class="creatCom" @click="createCom">
              <img :src="require('@/assets/img/creatCom.png')" alt="" srcset="" />
              <p>创建新的企业</p>
            </div>
            <div class="joinCom" @click="joinCom">
              <img :src="require('@/assets/img/joinCom.png')" alt="" srcset="" />
              <p>加入一个企业</p>
            </div>
          </div>
        </div>
        <!-- 创建新的企业 -->
        <div v-if="!Selection && createCompany" style="width: 4.75rem;">
          <div class="flex-between">
            <div><h2 class="modal1-title">创建新的企业</h2></div>
            <div>
              <a-button style="margin-right: 5px;" size="small" type="primary" ghost @click="handleReresh"
                >刷新</a-button
              >
              <a-button size="small" type="danger" ghost @click="handleLogout">退出</a-button>
            </div>
          </div>
          <p class="modal1-content">请填写企业真实信息以便项目维护</p>
          <div>
            <a-form :form="createForm" :label-col="{ span: 5 }" @submit="handleCreateSubmit">
              <a-form-item>
                <a-input
                  block
                  v-decorator="[
                    'company',
                    {
                      rules: [
                        { required: true, message: '请输入企业名称' },
                        { pattern: /^[\u4e00-\u9fa5a-zA-Z]+$/, message: '只能输入汉字和英文' }
                      ]
                    }
                  ]"
                  placeholder="请输入企业名称"
                />
              </a-form-item>
              <a-form-item>
                <a-cascader
                  :field-names="{ label: 'name', value: 'id', children: 'childRegions' }"
                  :options="options"
                  v-decorator="['area', { rules: [{ required: true, message: '请选择所在地区' }] }]"
                  placeholder="请选择所在地区"
                  @change="onChange"
                />
              </a-form-item>
              <a-form-item>
                <div class="flex-end">
                  <a-button @click="cancelCreate">
                    取消
                  </a-button>
                  <a-button
                    style="margin-left: 0.2rem;"
                    type="primary"
                    html-type="submit"
                    :loading="state.loginBtn"
                    :disabled="state.loginBtn"
                  >
                    确定
                  </a-button>
                </div>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <!-- 加入企业 -->
        <div v-if="!Selection && !createCompany && !showWait" style="width: 4.75rem;">
          <div class="flex-between">
            <div style="display: flex;">
              <h2 class="modal1-title">加入企业</h2>
              <el-button size="small" type="text" @click="recordCom()">我的申请记录</el-button>
            </div>
            <div>
              <a-button style="margin-right: 5px;" size="small" type="primary" ghost @click="handleReresh"
                >刷新</a-button
              >
              <a-button size="small" type="danger" ghost @click="handleLogout">退出</a-button>
            </div>
          </div>

          <p class="modal1-content">填写企业名称之后等待管理员审核</p>
          <div>
            <a-form :form="joinForm" :label-col="{ span: 5 }" @submit="handleJoinSubmit">
              <a-form-item>
                <!-- <a-input block v-decorator="['note', { rules: [{ required: true, message: '请输入企业名称' }] }]"
                  placeholder="请输入企业名称" /> -->

                <a-tree-select
                  show-search
                  style="width: 100%"
                  v-decorator="['companyid', { rules: [{ required: true, message: '请输入企业名称' }] }]"
                  placeholder="请输入企业名称"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  allow-clear
                  tree-default-expand-all
                  @search="onSearch"
                  @select="onSelect"
                  @change="handleChange"
                  :search-value.sync="searchValue"
                >
                  <a-tree-select-node
                    v-for="(item, index) in TenantsList"
                    :key="item.id"
                    :value="item.name"
                    :title="item.name"
                  ></a-tree-select-node>
                </a-tree-select>
              </a-form-item>
              <a-form-item>
                <a-input
                  :maxLength="50"
                  v-decorator="[
                    'name',
                    {
                      rules: [{ required: true, message: '请输入1-50字', max: 50, min: 1 }]
                    }
                  ]"
                  placeholder="请输入真实姓名"
                >
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-mentions
                  v-decorator="[
                    'reason',
                    {
                      rules: [{ required: true, message: '请输入', max: 100, min: 1 }]
                    }
                  ]"
                  rows="3"
                  placeholder="请输入申请理由"
                >
                </a-mentions>
              </a-form-item>

              <a-form-item>
                <div class="flex-end">
                  <a-button @click="cancelJoin">
                    取消
                  </a-button>
                  <a-button
                    style="margin-left: 0.2rem;"
                    type="primary"
                    html-type="submit"
                    :loading="state.loginBtn"
                    :disabled="state.loginBtn"
                  >
                    确定
                  </a-button>
                </div>
              </a-form-item>
            </a-form>
          </div>
          <recordFormDetails ref="record"></recordFormDetails>
        </div>
      </a-modal>
    </div>
    <div class="modal2" v-if="!Selection && showWait">
      <a-modal
        v-model="modal2Visible"
        :footer="null"
        :title="null"
        :closable="false"
        centered
        :keyboard="false"
        :maskClosable="false"
        width="600"
      >
        <div class="modal2-box">
          <img :src="require('@/assets/img/waitPass.png')" alt="" srcset="" />
          <p>
            等待管理员审核通过...<span style="margin-left: 10px;"
              ><a-button size="small" type="primary" ghost @click="handleReresh">刷新</a-button></span
            >
          </p>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { _refreshToken, _getAllRegion, _createTenant, _queryTenants, _joinTenants } from '@/api/keepHome'
import { getLoginUser } from '@/api/modular/system/loginManage'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import store from '@/store'
import recordFormDetails from './recordFormDetails.vue'
import { Mentions } from 'ant-design-vue'
Vue.use(Mentions)
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      modal1Visible: true,
      modal2Visible: true,
      Selection: true,
      createCompany: false,
      showData: '',
      showWait: false,
      // 创建企业form
      formLayout: 'horizontal',
      createForm: this.$form.createForm(this, { name: 'createForm' }),
      // 加入企业form
      joinForm: this.$form.createForm(this, { name: 'coordinated' }),
      options: [],
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      TenantsList: [],
      searchValue: '',
      companyid: '',
      addressName: '',
      userTimer: null // 刷新用户信息
    }
  },
  created() {
    this.showData = 'modal1' //首次进入空状态
    // this.showData = 'modal2';//首次进入待通过
    this.getRegion()
  },
  components: {
    recordFormDetails
  },
  mounted() {
    // this.userTimer = setInterval(() => {
    //   this.GetInfo()
    // }, 5000)
  },
  // beforeRouteLeave(to, from, next) {
  //   // 清除定时器
  //   clearInterval(this.userTimer)
  //   // 继续路由跳转
  //   next()
  // },
  methods: {
    ...mapActions(['Logout']),
    // 刷新
    handleReresh() {
      this.GetInfo()
    },
    // 退出登录
    handleLogout() {
      this.Logout({})
        .then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 16)
        })
        .catch(err => {
          this.$message.error({
            title: '错误',
            description: err.message
          })
        })
    },
    // 选择切换控制
    createCom() {
      this.Selection = false
      this.createCompany = true
    },
    joinCom() {
      this.Selection = false
      this.createCompany = false
    },
    // 我的申请记录
    recordCom() {
      this.$refs.record.add()
    },
    // 创建企业form
    cancelCreate() {
      this.Selection = true
      this.createCompany = false
    },
    // 提交创建企业form
    handleCreateSubmit(e) {
      e.preventDefault()

      const token = Vue.ls.get(ACCESS_TOKEN)
      console.log(token, '提交时获取当前token')

      this.state.loginBtn = true
      this.createForm.validateFields((err, values) => {
        if (!err) {
          let creatParams = {
            name: values.company,
            email: '',
            phone: '',
            regionId: values.area[values.area.length - 1],
            regionsName: this.addressName,
            linkMan: '',
            creditCode: '',
            address: '',
            remark: ''
          }
          console.log(creatParams, 'creatParams')
          console.log('Received values of form: ', values)
          // 创建新租户
          _createTenant(creatParams).then(res => {
            if (res.code == 200) {
              console.log('创建租户成功', res)
              this.state.loginBtn = false
              console.log(Vue.ls.get(ACCESS_TOKEN), '-----旧的token')
              // 刷新token
              let params = {
                tenantId: res.data
              }
              _refreshToken(params).then(res => {
                console.log('刷新token', res)
                if (res.code == 200) {
                  Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
                  store.commit('SET_TOKEN', res.data.accessToken)
                  console.log('新的token+++++++++', Vue.ls.get(ACCESS_TOKEN))
                  this.$router.push({ path: '/welcome' })
                  window.location.reload()
                } else {
                }
              })
            } else {
              this.$message.error(res.message)
              this.state.loginBtn = false
            }
          })
        } else {
          setTimeout(() => {
            this.state.loginBtn = false
          }, 600)
        }
      })
    },
    // 请求行政区划地址
    getRegion(val) {
      let params = val
      _getAllRegion(params).then(res => {
        this.options = res.data
      })
    },
    handleSelectChange(value) {
      console.log(value)
      this.form.setFieldsValue({
        company: `Hi, ${value === 'male' ? 'man' : 'lady'}!`
      })
    },
    // 加入企业form
    cancelJoin() {
      this.Selection = true
      this.createCompany = false
    },
    // 定时刷新token
    refreshToken() {
      const token = Vue.ls.get(ACCESS_TOKEN)
      console.log(token, '提交时获取当前token定时刷新token')
      // 刷新token
      let params = {
        tenantId: res.data
      }
      _refreshToken(params).then(res => {
        console.log('刷新token', res)
        if (res.code == 200) {
          Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
          store.commit('SET_TOKEN', res.data.accessToken)
          console.log('新的token+++++++++', Vue.ls.get(ACCESS_TOKEN))
          this.$router.push({ path: '/welcome' })
          window.location.reload()
        } else {
        }
      })
    },
    // 获取用户信息
    GetInfo() {
      return new Promise((resolve, reject) => {
        getLoginUser().then(response => {
          if (response.success) {
            console.log(response, 'huoqunewId')
            const data = response.data
            // ======过滤tenantId!=myTenants items==============================
            let isMatch = false
            data.myTenants.forEach(item => {
              if (item.id !== data.tenantId) {
                isMatch = true
                // alert('tenid=======')
              }
            })
            if (isMatch && data.myTenants.length > 0) {
              clearInterval(this.userTimer)
              // alert('!!!!!!!!!!!需要刷新token')
              const token = Vue.ls.get(ACCESS_TOKEN)
              console.log(token, '-----旧的token')
              // 刷新token
              let params = {
                tenantId: data.myTenants[0].id
              }
              _refreshToken(params).then(res => {
                if (res.code == 200) {
                  Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
                  store.commit('SET_TOKEN', res.data.accessToken)
                  console.log('新的token+++++++++', Vue.ls.get(ACCESS_TOKEN))
                  this.$router.push({ path: '/welcome' })
                  console.log(this.$router, 'this.$router')
                  window.location.reload()
                } else {
                }
              })
            } else if (data.myProjects.length > 0) {
              clearInterval(this.userTimer)
              // alert('!!!!!!!!!!!需要刷新token')
              const token = Vue.ls.get(ACCESS_TOKEN)
              console.log(token, '-----旧的token')
              // 刷新token
              let params = {
                tenantId: this.$store.state.user.comId,
                projectId: data.myProjects[0].id
              }
              _refreshToken(params).then(res => {
                if (res.code == 200) {
                  Vue.ls.set(ACCESS_TOKEN, res.data.accessToken /*, 7 * 24 * 60 * 60 * 1000 */)
                  store.commit('SET_TOKEN', res.data.accessToken)
                  console.log('新的token+++++++++', Vue.ls.get(ACCESS_TOKEN))
                  this.$router.push({ path: '/welcome' })
                  console.log(this.$router, 'this.$router')
                  window.location.reload()
                } else {
                }
              })
            } else {
              return
              this.$router.push({ path: '/welcome' })
              console.log(this.$router, 'this.$router')
              window.location.reload()
            }
            // ==================================================================

            resolve(data)
          } else {
            // eslint-disable-next-line no-undef
            reject(new Error(response?.message))
          }
        })
        //登录过期后前端会发生高频访问后端的问题
        //.catch(error => {
        //  reject(error)
        //})
      })
    },
    // 确定加入企业
    handleJoinSubmit(e) {
      e.preventDefault()

      this.state.loginBtn = true

      this.joinForm.validateFields((err, values) => {
        if (!err) {
          let params = {
            id: this.companyid,
            reason: values.reason,
            name: values.name
          }

          _joinTenants(params).then(res => {
            if (res.code == 200) {
              this.$message.success('申请成功，请等待管理员审核！')
              this.state.loginBtn = false
              this.showWait = true
            } else {
              this.$message.error(res.message)
              this.state.loginBtn = false
            }
          })
        } else {
          this.showWait = false
          this.state.loginBtn = false
        }
      })
    },
    // handleChange(value) {
    //   console.log(value);
    //   this.joinForm.setFieldsValue({
    //     note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
    //   });
    // },
    onChange(value) {
      // console.log(value, '---------新建企业选择的地区')
      this.addressName = this.extractNames(value, this.options)
      // this.area = value[value.length - 1]
      // console.log( this.area," this.area");
    },
    // 定义一个函数，传入一维数组和三维数组两个参数
    extractNames(idList, regions) {
      const nameList = []

      for (let i = 0; i < regions.length; ++i) {
        if (regions[i].id === idList[0]) {
          nameList.push(regions[i].name)
          // 第一级匹配
          for (let j = 0; j < regions[i].childRegions.length; ++j) {
            if (regions[i].childRegions[j].id === idList[1]) {
              nameList.push(regions[i].childRegions[j].name)
              // 第二级匹配
              for (let k = 0; k < regions[i].childRegions[j].childRegions.length; ++k) {
                if (regions[i].childRegions[j].childRegions[k].id === idList[2]) {
                  // 第三级匹配
                  nameList.push(regions[i].childRegions[j].childRegions[k].name)
                }
              }
            }
          }
        }
      }

      return nameList.join('/')
    },
    onSearch(value) {
      this.searchValue = value
      let params = { name: this.searchValue }
      _queryTenants(params).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          this.TenantsList = res.data
        }
      })
    },
    onSelect() {
      this.companyid = [...arguments][2].selectedNodes[0].data.key

      console.log(this.companyid, '-----this.companyid')
    },

    handleChange(value) {
      // console.log(value, "============");
    }
  }
}
</script>

<style lang="scss" scoped>
.modal1-title {
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #333333;
  margin-right: 20px;
}

.modal1-content {
  height: 0.21rem;
  font-size: 0.15rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #818c98;
}

.modal1-box {
  display: flex;
}

.creatCom,
.joinCom {
  width: 2.38rem;
  height: 2.14rem;
  cursor: pointer;
  padding: 0.31rem 0.39rem 0.34rem;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    border: 1px solid #4689f6;

    img {
    }

    p {
      color: #4689f6;
    }
  }

  img {
    width: 1.6rem;
    height: 1.15rem;
  }

  p {
    font-size: 0.18rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #818c98;
    text-align: center;
  }
}

.modal2-box {
  width: 5rem;
  height: 3.8rem;
  // cursor: pointer;
  padding: 0.31rem 0.39rem 0.34rem;
  box-sizing: border-box;
  border-radius: 4px;

  // &:hover {
  //     border: 1px solid #4689F6;

  //     img {}

  //     p {
  //         color: #4689F6;
  //     }
  // }

  img {
    width: 4.85rem;
    height: 3rem;
  }

  p {
    font-size: 0.18rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #818c98;
    text-align: center;
  }
}

::v-deep .ant-modal-content {
  border-radius: 10px;
}

// ::v-deep .ant-input:placeholder-shown {
//   border: 0;
//   border-bottom: 1px solid #d9d9d9;
// }
</style>
